import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
// import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-owl-carousel2/src/owl.carousel.css";
import "./assets/scss/App.scss";
import "./assets/customCss/style.css";
import rnLogoSm from "./assets/img/new/rn-logo-small.webp";
const App = lazy(() => import("./app/App"));

const loadingStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "100px",
};

const renderLoader = () => {
  return <img src={rnLogoSm} alt="Rookie Ninja Logo" style={loadingStyle} />;
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={renderLoader()}>
        <App />
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
